import Service from "../Service";
import Vue from "vue";

const resource = "ConfigurationPrice/";

export default{
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
          params: { requestID: requestID },
        });
      },
      save(prd, requestID) {
        return Service.post(resource + "save", prd, {
          params: { requestID: requestID },
        });
      },
      listPaymer(statusID, search, requestid){
   
        return Service.post(resource + "listCategoryPayment",{},{
          params:{StatusID: statusID, Search: search, RequestID: requestid},
        });
      },
      getUrllistPaymer(){
        return Vue.prototype.$http.defaults.baseURL + "/ConfigurationPrice/listCategoryPayment";
      },
}
