import Service from "../Service";

const resource = "configurationpricegroup/";


export default {
    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    list(requestID) {
        return Service.post(resource + "list", {}, {
            params: { requestID: requestID },
        });
    },

    detaillist(CpgID, requestID) {
        return Service.post(resource + "detaillist", {}, {
            params: { CpgID, requestID: requestID },
        });
    },

    groupconfigsave(obj, requestID) {
        return Service.post(resource + "groupconfigsave", obj, {
            params: { requestID: requestID },
        });
    },
};