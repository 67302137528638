<template
  ><div style="padding-top:-2px;margin-top:-2px;">
    <v-label
      ><b>{{ label }}</b></v-label
    >
    <s-select-generic
      autocomplete :autofocus=autofocus
      class="text"
      itemvalue="PrdID"
      :disabled="disabled"
      :value="value"
      id="PrdID" 
      itemtext="PrdCardName"
      :return-object="returnObject"
      @input="input($event)"
      :config="config"
      :full="full"
      :text="text"
      :clearable="clearable"
      :noDefault="noDefault"
      @onClearClicked="onClearClicked()"
      @change="changeProducer($event)"
    >
    </s-select-generic>
  </div>
</template>

<script>
import _sService from "@/services/Technicalassistance/HarvestSchedule.js";
export default {
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: null,
    text: { type: String, default: "" },
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    produceID:{
      type: Number,
      default: 0,
    },
    noDefault: {
      type: Boolean,
      default: false,
    },    
    full: {
      type: Boolean,
      default: false,
    },
    FagID: {
      type: Number,
      default: 0
    },
    onClear:{
                type: Boolean,
                default: false,
            },
  },
  watch: {
    text(){
    },
    value(value) {
      this.item = value;
    },
    FagID(){
      if(this.FagID != 0){
        console.log('cambio de fag ', this.FagID);
        this.config.params.FagID=this.FagID
      }
        
    }
  },
  methods: {
    changeProducer(data){
      if (this.item > 0) { 
        var prod = data.filter(x => x.PrdID == this.item);
        this.$emit('changeProd', prod[0])
      }
      
    },
    onClearClicked(){
      this.$emit("onClearClicked");     
    },
    input(val) {
      this.$emit("input", val);
    },
  },
  data: () => ({
    item: 0,
    val: "",
    items: [],
    config: {},
  }),

  created() {
      this.config = {
        url: _sService.getUrllistProducer(),
        title: this.label,
        params: {FagID:this.FagID, search: "", requestID: this.$fun.getUserID() },
      };
    
    
  },
};
</script>
